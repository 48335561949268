import { Classes, Popover } from '@blueprintjs/core';

interface IExerciseCardProps {
  title: string;
  description: string;
  readMore: string;
  iconSrc: string;
  interaction?: 'click' | 'hover-target';
}

const cropLimit = 185;

function cropString(str: string) {
  const cropped = str.length > cropLimit ? str.slice(0, cropLimit) : str;
  const trimmed = cropped.trimEnd();
  const ellipsis = trimmed.length < str.length ? trimmed + '...' : trimmed;
  return ellipsis;
}

export default function ExerciseCard({
  title,
  description,
  readMore,
  iconSrc,
  interaction = 'click',
}: Readonly<IExerciseCardProps>) {
  const croppedText = cropString(description);
  return (
    <Popover
      interactionKind={interaction}
      placement="top"
      content={description}
      className="exercise-card"
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
      <div className="exercise-card-target">
        <div className="exercise-card-icon-wrapper">
          <img className="exercise-card-icon" src={iconSrc} alt={title} />
        </div>
        <div className="exercise-card-wrapper">
          <p className="exercise-card-title">{title}</p>
          <p className="exercise-card-description">{croppedText}</p>
          <span className="exercise-card-readmore">{readMore}</span>
        </div>
      </div>
    </Popover>
  );
}
